import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";

import tony from "../assets/images/artists/tony/profile/tony-artist-profile.jpg";
import tony001 from "../assets/images/artists/tony/work/tony-001.jpg";
import tony002 from "../assets/images/artists/tony/work/tony-002.jpg";
import tony003 from "../assets/images/artists/tony/work/tony-003.jpg";
import tony004 from "../assets/images/artists/tony/work/tony-004.jpg";
import tony005 from "../assets/images/artists/tony/work/tony-005.jpg";
import tony006 from "../assets/images/artists/tony/work/tony-006.jpg";

const Artist_Tony = props => (
  <Layout>
    <SEO
      title="Artist Tony Tchamanian"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Tony Tchamanian</h1>
        </header>
        <div className="image">
          <img src={tony} alt="tony" className="image left" />
          <p>
            Tony started tattooing at the age of 20. He got offered an
            apprenticeship under 3 artists at Voodoo Dolls Tattoo in Jasper,
            Indiana where Tony worked as a tattoo artist and body piercer for 8
            years. During that time, he went through different styles and
            techniques of tattooing. Tony can do many styles of tattooing but
            primarily focuses on traditional and geometric style tattoos. He is
            always up for a challenge, and to try new concepts. In early 2018,
            Tony made the move back to his hometown of Los Angeles, and is now
            an artist at Obsidian LA in Silverlake. He accepts walk-ins as well
            as scheduled appointments, and can be contacted to answer any
            questions via email.{" "}
          </p>
          <p>Contact Email: ttchamanian@yahoo.com</p>
          <hr />
          <p style={{ float: `left`, marginTop: `.3em` }}>
            <a
              href="https://www.instagram.com/tonytchamaniantattoos/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp; @tonytchamaniantattoos
            </a>
          </p>
          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="tiles"
        style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
      >
        <article
          style={{
            backgroundImage: `url(${tony001})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${tony004})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${tony002})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${tony003})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${tony005})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${tony006})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
      </section>
    </div>
  </Layout>
);
// export const query = graphql`
// query ArtistTony {
//     allInstaNode (limit: 15) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `

export default Artist_Tony;
